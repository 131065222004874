
$(document).ready(function(){
    $(document).foundation();
});

// Original code
// $(document).foundation();

// Core

foundation.core.js

foundation.responsiveMenu.js
foundation.util.triggers.js
foundation.util.mediaQuery.js

foundation.responsiveToggle.js
foundation.util.mediaQuery.js
foundation.util.motion.js
